<template>
  <div>
    <van-cell-group title="填写详情">
      <van-field readonly label="仓库" placeholder="请选择仓库" is-link @click="ware.show = true" v-model="ware.txt" />
      <van-popup v-model="ware.show" round position="bottom">
        <van-picker :columns="ware.lis" show-toolbar @cancel="ware.show = false" @confirm="changeWare"
          value-key="STORAGE_NAME" />
      </van-popup>

      <van-field readonly label="分类" placeholder="请选择分类" is-link @click="cat.show = true" v-model="cat.txt" />
      <van-popup v-model="cat.show" round position="bottom">
        <van-cascader title="请选择分类" :options="cat.ls" @finish="catSel" @close="cat.show = false" v-model="model.CAT_ID"
          :field-names="{ text: 'NAME', value: 'ID', children: 'CHILDREN' }" />
      </van-popup>

      <van-field type="text" label="名称" v-model="model.NAME" placeholder="请填写名称" />
      <van-field type="text" label="单位" v-model="model.FUNIT" placeholder="请填写单位" />
      <van-field readonly label="状态" placeholder="请选择分类" is-link @click="type.show = true" v-model="type.txt" />
      <van-popup v-model="type.show" round position="bottom">
        <van-picker :columns="type.lis" show-toolbar @cancel="type.show = false" @confirm="changeType" />
      </van-popup>

      <van-field v-model="model.PRICE" type="digit" label="价格" placeholder="请输入价格" />
      <van-field v-model="model.BALANCE" type="digit" label="数量" placeholder="请输入数量" />
      <van-field type="text" label="条码" v-model="model.CODE" placeholder="中国物品编码（条码）" />
      <van-field v-model="model.DESCRIPTION" type="textarea" label="备注" :rows="2" />
      <van-field v-model="model.LOWER_LIMIT" type="digit" label="低值通知" placeholder="请输入低值通知" />
    </van-cell-group>

    <van-cell-group title="上传图片">
      <van-uploader :after-read="afterRead" v-model="picList" @delete="model.PIC_B64 = ''" max-count="1"
        style="margin: 10px 0px 0px 10px" />
    </van-cell-group>
    <div style="margin: 15px">
      <van-button type="primary" size="large" icon="success" block @click="submit" :loading="ret.loading"
        loading-text="正在提交。。。">确认提交</van-button>
      <br />
      <van-button plain type="default" size="large" icon="cross" block to="/mat">取消返回</van-button>
    </div>
  </div>
</template>
<style scoped></style>
<script>
  import { Toast } from 'vant';
  export default {
    name: "new",
    data() {
      return {
        model: {
          FUNIT: '个',
          PRICE: 0,
          LOWER_LIMIT: 0
        },
        cat: {
          show: false,
          ls: [],
          all: [],
          txt: ""
        },
        stock: {
          show: false,
          ls: [],
          txt: "",
          lss: [],
          key: ""
        },
        ed_show: false,
        teacher: {
          show: false,
          ls: [],
          lss: [],
          key: ""
        },
        picList: [],
        cla: {
          show: false,
          ls: [],
        },
        ret: {
          loading: false
        },
        type: {
          txt: '',
          show: false,
          lis: [{ text: '正常', value: 1 }, { text: '禁用', value: 2 }]
        },
        ware: {
          txt: '',
          show: false,
          lis: []
        }
      }
    },
    created() {
      this.getCat();
      this.getWare()
    },
    methods: {
      getCat() {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/Mobile/MAT/MCatApi/GetTree",
          completed: function (its) {
            self.cat.all = its.DATA;
          }
        })
      },
      catSel({ selectedOptions }) {
        this.cat.show = false;
        this.cat.txt = selectedOptions.map((option) => option.NAME).join('/');
      },
      getWare() {
        let self = this;
        this.whale.remote.getResult({
          url: '/api/Mobile/MAT/MatStorage/GetList',
          completed: function (its) {
            self.ware.lis = its.DATA
          }
        })
      },
      changeWare(e) {
        this.model.STORAGE_ID = e.ID
        this.ware.txt = e.STORAGE_NAME
        this.ware.show = false
        this.cat.ls = this.cat.all.filter(x => x.STORAGE_ID == e.ID)
        this.model.CAT_ID = 0
        this.cat.txt = ""
      },
      changeType(e) {
        this.model.STA = e.value
        this.type.txt = e.text
        this.type.show = false
      },
      afterRead(f) {
        let self = this;
        this.whale.image.compress({
          file: f.file,
          callback(f) {
            self.whale.image.toB64({
              file: f,
              callback(b64) { self.model.PIC_B64 = b64 }
            })
          }
        })
      },
      submit() {
        if (!this.model.STORAGE_ID) {
          Toast.fail("请选择仓库")
          return
        }
        if (!this.model.CAT_ID) {
          Toast.fail("请选择分类")
          return
        }
        if (!this.model.STA) {
          Toast.fail("请选择状态")
          return
        }
        // console.log(this.model)

        let self = this;
        self.ret.loading = true;
        this.whale.remote.getResult({
          url: "/api/Mobile/MAT/MStockApi/Save",
          data: this.model,
          finally() {
            self.ret.loading = false;
          },
          completed() {
            self.$dialog.confirm({
              title: "完成",
              message: "记录已成功提交！",
              theme: "round",
              confirmButtonText: "继续录入",
              cancelButtonText: "返回"
            }).then(() => {
              self.$router.go(0);
            }).catch(() => {
              self.$router.push("/mat")
            })
          }
        })
      }
    }
  }
</script>